( function() {
  //initialize swiper
  var mySwiper = new Swiper ( '.home-slider', {
    // Optional parameters
    // direction: 'vertical',
    loop: true,
    effect: 'fade',
    autoplay: {
      delay: 5000,
    },

    // If we need pagination
    pagination: {
      el: '.swiper-pagination',
    },

    // Navigation arrows
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  } );

  var mySwiper = new Swiper ( '.image-slider-mobile', {
    // Optional parameters
    // direction: 'vertical',
    loop: true,
    effect: 'fade',
    autoplay: {
      delay: 5000,
    },

    // If we need pagination
    pagination: {
      el: '.swiper-pagination',
    },

    // Navigation arrows
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  } );

  //marketplace single
  var marketplaceSwiper = new Swiper ( '.swiper-mk-container', {
    // Optional parameters
    // direction: 'vertical',
    loop: true,
    effect: 'fade',

    // If we need pagination
    pagination: {
      el: '.swiper-pagination',
    },

    // Navigation arrows
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  } );
  const mkThumbs = document.querySelectorAll('.single-gallery-thumb');
  
  for (const mkThumb of mkThumbs) {
    mkThumb.addEventListener('click', function() {
      // console.log(this.dataset.slide)
      marketplaceSwiper.slideTo(this.dataset.slide, 0);
    });
  }

  var mySwiper3 = new Swiper ( '.multi-image-container', {
    
        slidesPerView: 'auto',
        spaceBetween: 5,
        loop: true,
        
      // If we need pagination
      pagination: {
        el: '.swiper-pagination',
      },
      // Navigation arrows
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    } );
  console.log('working?');

  //add header class on scroll
  let scrollpos = window.scrollY
  const header = document.querySelector(".gc-site-header")
  const header_height = header.offsetHeight

  const add_class_on_scroll = () => header.classList.add("gc-site-header-scrolled")
  const remove_class_on_scroll = () => header.classList.remove("gc-site-header-scrolled")

  const remove_init_class_on_scroll = () => header.classList.remove("gc-site-header-unscrolled")
  const add_init_class_on_scroll = () => header.classList.add("gc-site-header-unscrolled")

  window.addEventListener('scroll', function() { 
    scrollpos = window.scrollY;

    if (scrollpos >= header_height) { add_class_on_scroll(), remove_init_class_on_scroll() }
    else { remove_class_on_scroll(), add_init_class_on_scroll() }

    // console.log(scrollpos)
  });
  

  //nav button interaction
  const topLevelButtons = document.querySelectorAll( '.js--top-level-button' );

  topLevelButtons.forEach( ( button ) => {
    button.addEventListener( 'click', () => {
      const moreButton = document.querySelector( 'js--top-level-more-button' );
      const isChildOfMore = !!button.closest( '.js--desktop-menu-more' ) && !button.classList.contains( 'js--top-level-more-button' );

      if ( isChildOfMore ) {
        document.querySelectorAll( '.js--desktop-menu-more-list .js--top-level-button' ).forEach( loopButton => {
          if ( !loopButton.isEqualNode( button ) ) {
            loopButton.classList.remove( 'menu-beside-open' );
          }
        } );

      } else {
        topLevelButtons.forEach( loopButton => {
          if ( !loopButton.isEqualNode( button ) ) {
            loopButton.classList.remove( 'menu-beside-open' );
          }
        } );
      }


      button.classList.toggle( 'menu-beside-open' );
    } );
  } );

  // close menu when click outside of menu
  document.addEventListener('click', function(e) {
      var element = document.getElementById('nav-main');

      if (e.target !== element && !element.contains(e.target)) {
        var openMenus = element.querySelectorAll('.menu-beside-open');

        if (!openMenus) {
          return;
        }

        openMenus.forEach( menu => {
          menu.classList.remove('menu-beside-open');
        } );
      }
  });
  // end menu when click outside of menu

  //mobile menu
  const mobileMenuControl = document.querySelector( '.js--mobile-menu-control' );
  const mobileMenu = document.querySelector( '.main-navigation-menu' );
  mobileMenuControl.addEventListener('click', () => {
    console.log('click');
    mobileMenu.classList.toggle('js--mobile-close');
  } );

  //accordion interaction
  const accordionControl = document.querySelectorAll( '.js--accordion-control' );

  accordionControl.forEach( ( accordionButton ) => {

    accordionButton.addEventListener( 'click', () => {

      const nextSection = accordionButton.nextElementSibling;
      accordionButton.classList.toggle('expanded');
        nextSection.classList.toggle('js--accordion-open');
      
        if (accordionButton.classList.contains('expanded')) {
          accordionButton.setAttribute('aria-expanded', true)
          nextSection.setAttribute('aria-hidden', false)
        } else {
          accordionButton.setAttribute('aria-expanded', false)
          nextSection.setAttribute('aria-hidden', true)
        }

    });

  } );  
  //end accordion interaction


  //photo pop up interaction
  const photoPopupControl = document.querySelectorAll( '.photo-popup-control' );
  photoPopupControl.forEach( ( photoPopup ) => {
    photoPopup.addEventListener('click', () => {
      const getPhotoPopup =  photoPopup.previousElementSibling;
      getPhotoPopup.classList.toggle('open');
      console.log(getPhotoPopup);
      // console.log('click');
      // if (getPhotoPopup.classList.contains('open')) {
      //   console.log('menu open');
      //   getPhotoPopup.classList.remove('open');
      //   getPhotoPopup.classList.add('close');  
      // } 
      // if (getPhotoPopup.classList.contains('close')) {  
      //   console.log('menu closed');
      //   getPhotoPopup.classList.remove('close');
      //   getPhotoPopup.classList.add('open');
      // };
    } );
   } ); 
  //photo pop up interaction

  // close popup when click outside of popup
  document.addEventListener('click', function(e) {
      var popUpWrapper = document.querySelectorAll('.photo-popup-wrapper');
      // console.log(popUpWrapper);

      popUpWrapper.forEach( wrapper => { 

        if (e.target !== wrapper && !wrapper.contains(e.target)) {
          var openPop = wrapper.querySelectorAll('.open');

          if (!openPop) {
            return;
          }

          openPop.forEach( popUp => {
            popUp.classList.remove('open');
          } );
        }

      } );
  });
  // end close popup when click outside of popup

  //members menu interaction
  const memberMenuControl = document.getElementById( 'open-members-menu' );
  if (memberMenuControl) {

    memberMenuControl.addEventListener( 'click', () => {
      const memberPageMenu = document.getElementById( 'members-menu' );
      
      memberMenuControl.classList.toggle('open');

      if (memberPageMenu.classList.contains('open')) {
        console.log('menu open');
        memberPageMenu.classList.remove('open');
        memberPageMenu.classList.toggle('close');
      } else {
        console.log('menu closed');
        memberPageMenu.classList.remove('close');
        memberPageMenu.classList.toggle('open');
      };
    });

  };


  //members menu interaction


  //timeline interactions
  const timelineReadMore = document.querySelectorAll('.timeline-readmore');

  timelineReadMore.forEach( ( readMore ) => {

    readMore.addEventListener( 'click', () => {

      const timelineModule = readMore.closest('.timeline-module');
      const additionalText = timelineModule.querySelector('.timeline-module--additional');  
      const buttonText = readMore.querySelector('.button-text');
      console.log(readMore);
      console.log(additionalText);
      console.log(timelineModule);
      if (readMore.classList.contains('timeline-readmore-closed')) {
          console.log('closed');
          //change button class
          readMore.classList.remove('timeline-readmore-closed')
          readMore.classList.add('timeline-readmore-open')
          buttonText.innerHTML = "close";  
          //change additional text class
          additionalText.classList.remove('timeline-module--additional-closed')
          additionalText.classList.add('timeline-module--additional-open')
      } else if (readMore.classList.contains('timeline-readmore-open')) {
          console.log('open');
          //change button class
          readMore.classList.remove('timeline-readmore-open')
          readMore.classList.add('timeline-readmore-closed')
          buttonText.innerHTML = "read more";  

          //change additional text class
          additionalText.classList.add('timeline-module--additional-closed')
          additionalText.classList.remove('timeline-module--additional-open')
      }        

    });

  } );  

} )();

