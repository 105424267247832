( function() {
	// NOTE - notifcation bar is hidden by default using a class; eg 'dummy-bar-hidden'
	const memberForm = document.querySelector( '#members-password-form' );
	const memberFormSection = document.querySelector( '#members-password-form-wrapper' );
	const memberPageSection = document.querySelector( '#members-page-wrapper' );

	if ( !memberForm ) {
		return;
	}

	// Get value of cookie
	const hideCookie = Cookies.get( 'hide-member-info' );

	// If cookie doesn't exist, show the password box by removing class that hides it
	if ( hideCookie === undefined ) {
		memberFormSection.classList.remove( 'member-form-hidden' );
		memberPageSection.remove( memberPageSection );
		
	} else {
		memberPageSection.classList.remove( 'member-section-hidden' );
		
		const storyQueryData = {
		  'action': 'memberspage',
		  'page_id' : gc_vars.page_id
		};

		const httpRequest = new XMLHttpRequest();

		if (!httpRequest) {
		  alert('Giving up :( Cannot create an XMLHTTP instance');
		  return false;
		}
		httpRequest.onreadystatechange = alertContents;
		httpRequest.open('POST', gc_vars.ajaxurl);
		httpRequest.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
		httpRequest.send(Object.keys(storyQueryData).map(key => key + '=' + storyQueryData[key]).join('&'));
		
		function alertContents() {
		  if (httpRequest.readyState === XMLHttpRequest.DONE) {
		    if (httpRequest.status === 200) {
		      const response = JSON.parse(httpRequest.responseText);

		      //add page content	
		     	document.querySelector('.members-page-section-ajax').innerHTML += response.post;

		     	document.querySelector('.loader-members').remove();

		      	//re init form
		    	document.querySelectorAll(".wpcf7 > form").forEach((
		        	function(e){
		            	// return wpcf7.initForm(e) 
		            	return wpcf7.init(e)
		        	}
		    	));

		    	//accordion interaction
		    	const accordionControl = document.querySelectorAll( '.js--accordion-control' );

		    	accordionControl.forEach( ( accordionButton ) => {

		    	  accordionButton.addEventListener( 'click', () => {

		    	    const nextSection = accordionButton.nextElementSibling;
		    	    accordionButton.classList.toggle('expanded');
		    	      nextSection.classList.toggle('js--accordion-open');
		    	    
		    	      if (accordionButton.classList.contains('expanded')) {
		    	        accordionButton.setAttribute('aria-expanded', true)
		    	        nextSection.setAttribute('aria-hidden', false)
		    	      } else {
		    	        accordionButton.setAttribute('aria-expanded', false)
		    	        nextSection.setAttribute('aria-hidden', true)
		    	      }

		    	  });

		    	} );  
		    	//end accordion interaction

		    } else {
		      console.log('There was a problem with the request.');
		    }
		  }
		}


	}

	// form interaction
	document.querySelector( '#members-password-form' ).addEventListener( 'submit', () => {
		event.preventDefault();
		
		const pass = document.getElementById('js-pwbox').value; 
		const passRegex = new RegExp('^([u][b][u][n][t][u])$');
		const membersFormMessage = document.getElementById('members-form-alert');

		if (passRegex.test(pass) === true) {
			// console.log('yesy');
			// console.log(pass);
			Cookies.set( 'hide-member-info', 'yesyes' ); 
			
			location.reload();

			if (!membersFormMessage.classList.contains('members-form-alert-hidden')) {
				membersFormMessage.classList.add('members-form-alert-hidden');
			};
		} else {
			// console.log('no');
			// console.log(pass);
			if (membersFormMessage.classList.contains('members-form-alert-hidden')) {
				membersFormMessage.classList.remove('members-form-alert-hidden');
			};
		};
	} );

} )();
