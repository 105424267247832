( function() {
	// Menu
	const menuParent = document.querySelector( '.js--desktop-menu-wrap' );
	const menu = document.querySelector( '.js--desktop-menu' );
	const menuParentWidth = menuParent.offsetWidth;
	let menuWidth = menu.offsetWidth;

	// '+ More' overflow list
	const overflowItem = document.querySelector( '.js--desktop-menu-more' );
	const overflowItemList = document.querySelector( '.js--desktop-menu-more-list' );

	// Media query
	const desktopMq = window.matchMedia( '(min-width: 480px)' );

	let isOverflowing = menuWidth > menuParentWidth;

	putAway();

	window.addEventListener( 'resize', function() {
		menuWidth = menu.offsetWidth;
		isOverflowing = menuWidth > menuParent.offsetWidth;

		if ( overflowItemList.children.length ) {
			overflowItem.classList.remove( 'is-hidden' );
		} else {
			overflowItem.classList.add( 'is-hidden' );
		}

		if ( desktopMq.matches ) {
			if ( isOverflowing ) {
				putAway();
			} else {
				putBack();
			}
		} else {
			if ( overflowItemList.children.length ) {
				Array.from( overflowItemList.children ).map( item => {
					menu.insertBefore( item, overflowItem );
				} );
			}
		}
	} );

	function putAway() {
		// If width of inner element is larger than its parent, it's overflowing
		if ( desktopMq.matches ) {
			if ( isOverflowing ) {

				// Keep moving menu items to the '+ More' overflow list until we're no longer overflowing
				while ( isOverflowing ) {
				// Get the last item before the '+ More' list item
					const lastItem = overflowItem.previousElementSibling;

					// Move it to the overflow list
					overflowItemList.prepend( lastItem );

					// Show overflow list
					if ( overflowItem.classList.contains( 'is-hidden' ) ) {
						overflowItem.classList.remove( 'is-hidden' );
					}

					// Update the width of the inner element
					menuWidth = menu.offsetWidth;

					// Update whether or not we're overflowing
					isOverflowing = menuWidth > menuParent.offsetWidth;
				}
			}
		}
	}

	function putBack() {
		if ( overflowItemList.children.length ) {
			// Calculate its width, as if it was a non-overflow item
			const itemWidthWithMargin = 150;

			// Check if it'll fit
			if ( ( menuWidth + itemWidthWithMargin ) <= menuParent.offsetWidth ) {

				menu.insertBefore( overflowItemList.children[ 0 ], overflowItem );

				// Update the width of the inner element
				menuWidth = menu.offsetWidth;
			}

			if ( !overflowItemList.children.length ) {
				overflowItem.classList.add( 'is-hidden' );
			}

		} else {
			// Hide overflow list
			if ( !overflowItem.classList.contains( 'is-hidden' ) ) {
				overflowItem.classList.add( 'is-hidden' );
			}
		}
	}

} )();
