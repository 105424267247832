( function() {
	// NOTE - notifcation bar is hidden by default using a class; eg 'dummy-bar-hidden'
	const notificationBar = document.querySelector( '#notification-bar' );

	if ( !notificationBar ) {
		return;
	}

	// Get value of cookie
	const hideCookie = Cookies.get( 'hide-notification-bar' );

	// If cookie doesn't exist, show the notification bar by removing class that hides it
	if ( hideCookie === undefined ) {
		notificationBar.classList.remove( 'notification-bar-hidden' );
	}

	// When notification bar is closed, set cookie and have it expire once user quits their browser
	document.querySelector( '#dismiss-notification-bar' ).addEventListener( 'click', () => {
		notificationBar.remove( notificationBar );

		Cookies.set( 'hide-notification-bar', 'yesyes' ); // value can be anything you want, like 'yesyes'. we only really care if the cookie exists
	} );

} )();
