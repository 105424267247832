( function() {
	const mapContainer = document.querySelector( '#map' );

	// If map container doesn't exist, or if groups data json doesn't exist
	if ( mapContainer === null || typeof groupsMap === 'undefined' ) {
		return;
	}

	const map = new google.maps.Map( mapContainer, {
		disableDefaultUI: true,
		zoomControl: true,
		mapTypeControl: false,
		streetViewControl: false,
		styles: [
			{
				'featureType': 'administrative',
				'elementType': 'geometry.stroke',
				'stylers': [
					{
						'color': '#b8a5b6',
					},
				],
			},
			{
				'featureType': 'administrative',
				'elementType': 'labels.text.fill',
				'stylers': [
					{
						'color': '#653d61',
					},
				],
			},
			{
				'featureType': 'administrative',
				'elementType': 'labels.text.stroke',
				'stylers': [
					{
						'color': '#fdfdfd',
					},
				],
			},
			{
				'featureType': 'landscape',
				'elementType': 'all',
				'stylers': [
					{
						'color': '#f2f2f2',
					},
				],
			},
			{
				'featureType': 'landscape',
				'elementType': 'geometry.fill',
				'stylers': [
					{
						'color': '#f8f3f7',
					},
				],
			},
			{
				'featureType': 'poi',
				'elementType': 'all',
				'stylers': [
					{
						'visibility': 'off',
					},
				],
			},
			{
				'featureType': 'road',
				'elementType': 'all',
				'stylers': [
					{
						'saturation': -100,
					},
					{
						'lightness': 45,
					},
				],
			},
			{
				'featureType': 'road.highway',
				'elementType': 'all',
				'stylers': [
					{
						'visibility': 'simplified',
					},
				],
			},
			{
				'featureType': 'road.highway',
				'elementType': 'geometry',
				'stylers': [
					{
						'visibility': 'on',
					},
				],
			},
			{
				'featureType': 'road.highway',
				'elementType': 'geometry.fill',
				'stylers': [
					{
						'color': '#e6dce5',
					},
				],
			},
			{
				'featureType': 'road.highway',
				'elementType': 'geometry.stroke',
				'stylers': [
					{
						'color': '#d8b6d5',
					},
				],
			},
			{
				'featureType': 'road.highway',
				'elementType': 'labels.text',
				'stylers': [
					{
						'color': '#3c3c3c',
					},
				],
			},
			{
				'featureType': 'road.arterial',
				'elementType': 'geometry.stroke',
				'stylers': [
					{
						'color': '#e6dce5',
					},
				],
			},
			{
				'featureType': 'road.arterial',
				'elementType': 'labels.text',
				'stylers': [
					{
						'color': '#7f7f7f',
					},
				],
			},
			{
				'featureType': 'road.arterial',
				'elementType': 'labels.text.stroke',
				'stylers': [
					{
						'color': '#ffffff',
					},
				],
			},
			{
				'featureType': 'road.arterial',
				'elementType': 'labels.icon',
				'stylers': [
					{
						'visibility': 'off',
					},
				],
			},
			{
				'featureType': 'road.local',
				'elementType': 'geometry.fill',
				'stylers': [
					{
						'color': '#ffffff',
					},
				],
			},
			{
				'featureType': 'road.local',
				'elementType': 'geometry.stroke',
				'stylers': [
					{
						'color': '#ede4ec',
					},
				],
			},
			{
				'featureType': 'road.local',
				'elementType': 'labels.text.fill',
				'stylers': [
					{
						'color': '#8c8c8c',
					},
				],
			},
			{
				'featureType': 'road.local',
				'elementType': 'labels.text.stroke',
				'stylers': [
					{
						'color': '#ffffff',
					},
				],
			},
			{
				'featureType': 'transit',
				'elementType': 'all',
				'stylers': [
					{
						'visibility': 'off',
					},
				],
			},
			{
				'featureType': 'water',
				'elementType': 'all',
				'stylers': [
					{
						'color': '#46bcec',
					},
					{
						'visibility': 'on',
					},
				],
			},
			{
				'featureType': 'water',
				'elementType': 'geometry.fill',
				'stylers': [
					{
						'color': '#b7e4f9',
					},
				],
			},
			{
				'featureType': 'water',
				'elementType': 'geometry.stroke',
				'stylers': [
					{
						'color': '#ace5ff',
					},
				],
			},
			{
				'featureType': 'water',
				'elementType': 'labels.text',
				'stylers': [
					{
						'color': '#0d587a',
					},
				],
			},
			{
				'featureType': 'water',
				'elementType': 'labels.text.stroke',
				'stylers': [
					{
						'color': '#ace5ff',
					},
				],
			},
		],
	} );

	let mapMarkers = [];
	let bounds = new google.maps.LatLngBounds();
	let infoWindow = new google.maps.InfoWindow();
	let marker;


	// Loop through our array of markers & place each one on the map
	groupsMap.map( function( group ) {

		const position = new google.maps.LatLng( group.location.lat, group.location.lng );

		bounds.extend( position );

		marker = new google.maps.Marker( {
			position: position,
			map: map,
			title: group.name,
			city: group.location.city,
			name: group.location.name,
			province: group.location.state_short,
			country: group.location.country,
			website1: group.website1,
			website2: group.website2,
			email: group.email,
			icon: {
				url: group.icon,
				scaledSize: new google.maps.Size( 21, 39 ),
			},
			greenIcon: {
				url: group.icon,
				scaledSize: new google.maps.Size( 21, 39 ),
			},
			lightGreenIcon: {
				url: group.lightGreenIcon,
				scaledSize: new google.maps.Size( 21, 39 ),
			},
		} );

		mapMarkers.push( marker );

		// Allow each marker to have an info window
		google.maps.event.addListener( marker, 'click', ( function( marker ) {
			return function() {
				infoWindow.setContent( `
					<div class="groups-popup">
						<div class="groups-header-bar">
							<h3 class="">${marker.title}</h3>
						</div>
						<div class="groups-content">
							
							<p>${marker.city ? `<span>${marker.city}</span>` : `<span>${marker.name}</span>` }, ${marker.province ? `<span>${marker.province}</span>` : `<span>${marker.country}</span>` }</p>
								
						</div>
						<div class="groups-links-bar">
							${marker.website1 ? `<a target="_blank" href="${marker.website1}" class="groups-website">Website</a>` : '' }
							${marker.website2 ? `<a target="_blank" href="${marker.website2}" class="groups-website">Facebook</a>` : '' }
							${marker.email ? `<a href="mailto:${marker.email}" class="groups-email">Email</a>` : '' }
						</div>
					</div>
				` );
				infoWindow.open( map, marker );

				mapMarkers.map( function( loopedMarker ) {
					if ( marker !== loopedMarker ) {
						loopedMarker.setIcon(loopedMarker.lightGreenIcon);
					} else {
						loopedMarker.setIcon(loopedMarker.greenIcon);
					}
				} );
			};
		} )( marker ) );

		// Close infowindow when map is clicked
		google.maps.event.addListener( map, 'click', function( event ) {
			infoWindow.close();
		} );

		// Automatically center the map fitting all markers on the screen
		map.fitBounds( bounds );
	} );


} )();
