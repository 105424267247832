( function() {
	const lowerDrawer = document.querySelector( '#lower-drawer' );

	if ( !lowerDrawer ) {
		return;
	}

	// Get value of cookie
	const hideLBCookie = Cookies.get( 'hide-lower-drawer' );
		console.log('yes')

	// If cookie doesn't exist, show the notification bar by removing class that hides it
	if ( hideLBCookie === undefined ) {
		lowerDrawer.classList.remove( 'lower-drawer-hidden' );
	}

	// When notification bar is closed, set cookie and have it expire once user quits their browser
	document.querySelector( '#dismiss-lower-drawer' ).addEventListener( 'click', () => {
		lowerDrawer.remove( lowerDrawer );

		Cookies.set( 'hide-lower-drawer', 'okok' ); // value can be anything you want, like 'yesyes'. we only really care if the cookie exists
	} );

} )();
