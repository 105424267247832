( function() {
	const resizeObserver = new ResizeObserver( _debounce( entries => {
		for ( let entry of entries ) {
			if ( entry.contentRect ) {
				document.documentElement.style.setProperty( '--header-height', `${entry.contentRect.height}px` );
			}
		}
	}, 500 ) );

	resizeObserver.observe( document.querySelector( '.gc-site-header' ) );
} )();
